import { Link } from "react-router-dom";
import {
  Box,
  Image,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Icon,
  HStack,
  VStack,
  useDisclosure,
  Avatar,
  Text,
} from "@chakra-ui/react";
import { AiOutlineMenu } from "react-icons/ai";
import Searchbar from "./Searchbar";
import logoIcon from "../assets/Leaderbored_logo_HD.webp";
import { getAuthToken } from "../utilities/auth";
import loginIcon from "../assets/Nav-Icons/LBPortal_Graphic_Login.png";

function MobileNav({ navItems, socials }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const token = getAuthToken();

  const renderNavItems = navItems.map((item) => {
    return (
      <Box
        onClick={onClose}
        borderRadius='10px'
        as={Link}
        key={item.text}
        to={item.path}
      >
        <HStack p='4px' gap='8px'>
          <Box>{item.text}</Box>
          <Image loading='lazy' src={item.icon} alt='Navigation icon' />
        </HStack>
      </Box>
    );
  });

  const renderSocials = socials.map((social) => {
    return (
      <Link key={social.link} target='_blank' to={social.link}>
        <Icon as={social.icon} boxSize={8} />
      </Link>
    );
  });

  return (
    <>
      <HStack w='100%' mx='16px' justifyContent='space-between'>
        <Icon fontSize='40px' as={AiOutlineMenu} onClick={onOpen} />
        <Box as={Link} to='/'>
          <Image src={logoIcon} alt='leaderbored logo' w='72px' />
        </Box>
        <Box boxSize={10}>
          {token && (
            <Link to='/account'>
              <Avatar as='button' bg='primaryLight' boxSize={10} />
            </Link>
          )}
        </Box>
      </HStack>
      <Drawer placement='left' onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay>
          <DrawerContent color='white' bgColor='primary'>
            <DrawerCloseButton />
            <DrawerBody marginTop='56px'>
              <Box marginBottom='32px'>
                <Searchbar />
              </Box>
              <VStack gap='16px' alignItems='end'>
                {renderNavItems}
                {!token && (
                  <HStack
                    onClick={onClose}
                    as={Link}
                    to='/auth'
                    p='4px'
                    gap='8px'
                  >
                    <Text>Login</Text>
                    <Image src={loginIcon} alt='loginIcon' loading='lazy' />
                  </HStack>
                )}
              </VStack>
              <HStack
                gap='16px'
                mt='24px'
                alignItems='center'
                justifyContent='end'
              >
                {renderSocials}
              </HStack>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
}

export default MobileNav;
