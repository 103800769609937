import { redirect } from "react-router-dom";
import axiosInstance from "../axios/axiosInstance";

export function getAuthToken() {
  const token = localStorage.getItem("token");
  return token;
}
export function getCurUser() {
  const user = JSON.parse(localStorage.getItem("user"));
  return user;
}

export function removeUserAndToken() {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
}

export function checkAuthLoader() {
  const token = getAuthToken();
  if (token) return redirect("/");
  else return null;
}

export async function updateUserCache() {
  const token = getAuthToken();
  const res = await axiosInstance.get("users/me", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const updatedUser = { ...res.data, email: undefined };
  localStorage.removeItem("user");
  localStorage.setItem("user", JSON.stringify(updatedUser));
}

export async function handleAuthSuccess(data, toast, toastTitle) {
  const { jwt, user } = data;
  localStorage.setItem("token", jwt);
  localStorage.setItem("user", JSON.stringify(user));
  toast({
    title: toastTitle,
    description: "Redirecting to the home page",
    status: "success",
    position: "top",
    isClosable: true,
  });
  setTimeout(() => {
    window.location.href = "/";
  }, 1500);
}
