import { Box, Image, useMediaQuery } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import Btn from "../UI/Btn";

import placeholder from "../../assets/GameThumbnail_Empty.png";

function CarouselItem({ banner, title, gameId }) {
  const [isLargerThan400] = useMediaQuery("(min-width: 400px)");

  return (
    <Box pos='relative'>
      <Link to={`/games/${gameId}`}>
        <Image
          w='100%'
          h={{ base: "280px", md: "auto" }}
          src={placeholder}
          alt='Game banner'
          objectFit='cover'
          objectPosition='center'
          loading='lazy'
          onLoad={(e) => (e.target.src = banner)}
        />
        <Image
          w={{ base: "80px", sm: "120px", md: "200px", lg: "240px" }}
          pos='absolute'
          left={{ base: "24px", md: "40px" }}
          top='40%'
          transform='translate(20%,-50%)'
          src={placeholder}
          alt='Game title'
          loading='lazy'
          onLoad={(e) => (e.target.src = title)}
        />
      </Link>

      {isLargerThan400 && (
        <Btn
          as={Link}
          to={`/games/${gameId}`}
          pos='absolute'
          // bottom='20%'
          bottom={{ base: "40px", lg: "80px" }}
          left='50%'
          transform='translateX(-50%)'
          bgColor='secondary'
          shadowcolor='#BC6022'
          animate='false'
        >
          Play Now!
        </Btn>
      )}
    </Box>
  );
}

export default CarouselItem;
