import { InputGroup, Input, InputRightElement } from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";
import { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";

function Searchbar() {
  const [searchValue, setSearchValue] = useState("");

  const [placeholder, setPlaceholder] = useState("");
  const navigate = useNavigate();

  const searchHandler = (e) => {
    e.preventDefault();

    if (searchValue.trim().length < 1) return;
    navigate({
      pathname: "search",
      search: createSearchParams({
        query: searchValue.toLocaleLowerCase().trim(),
      }).toString(),
    });
    setSearchValue("");
  };
  return (
    <InputGroup onSubmit={searchHandler} overflow='hidden' as='form'>
      <Input
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue}
        color='black'
        bgColor='white'
        placeholder={placeholder}
        onFocus={() => setPlaceholder("name or genre")}
        onBlur={() => setPlaceholder("")}
      />
      <InputRightElement
        bgColor='primaryLight'
        borderRadius='15%'
        as='button'
        children={<Search2Icon />}
      />
    </InputGroup>
  );
}

export default Searchbar;
