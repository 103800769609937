import { Box, HStack, useMediaQuery } from "@chakra-ui/react";
import Btn from "../UI/Btn";
import ShareModal from "./ShareModal";

import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
function ComicControls(props) {
  const {
    prevHandler,
    firstHandler,
    randomHandler,
    nextHandler,
    lastHandler,
    shareData,
    isOnEnd,
  } = props;

  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  if (!isLargerThan768)
    return (
      <Box>
        <HStack gap='8px' color='black' w='fit-content' mx='auto' pb='24px'>
          {isOnEnd !== "first" && (
            <Btn
              onClick={prevHandler}
              shadowcolor='#BC6022'
              bgColor='secondary'
            >
              <ArrowLeftIcon
                color='white'
                stroke='secondaryDark'
                fontSize='24px'
              />
            </Btn>
          )}
          <Btn shadowcolor='#BC6022' bgColor='secondary' onClick={firstHandler}>
            FIRST
          </Btn>
          <Btn onClick={lastHandler} shadowcolor='#BC6022' bgColor='secondary'>
            LAST
          </Btn>
          {isOnEnd !== "last" && (
            <Btn
              onClick={nextHandler}
              shadowcolor='#BC6022'
              bgColor='secondary'
            >
              <ArrowRightIcon
                color='white'
                stroke='secondaryDark'
                fontSize='24px'
              />
            </Btn>
          )}
        </HStack>
        <HStack mb='32px' mt='16px' justifyContent='center'>
          <Btn
            onClick={randomHandler}
            shadowcolor='#BC6022'
            bgColor='secondary'
          >
            RANDOM
          </Btn>
          <ShareModal data={shareData} />
        </HStack>
      </Box>
    );

  return (
    <HStack gap='8px' mb='32px' color='black' w='fit-content' mx='auto'>
      {isOnEnd !== "first" && (
        <Btn onClick={prevHandler} shadowcolor='#BC6022' bgColor='secondary'>
          <ArrowLeftIcon color='white' stroke='secondaryDark' fontSize='24px' />
        </Btn>
      )}

      <Btn shadowcolor='#BC6022' bgColor='secondary' onClick={firstHandler}>
        FIRST
      </Btn>
      <ShareModal data={shareData} />
      <Btn onClick={randomHandler} shadowcolor='#BC6022' bgColor='secondary'>
        RANDOM
      </Btn>
      <Btn onClick={lastHandler} shadowcolor='#BC6022' bgColor='secondary'>
        LAST
      </Btn>
      {isOnEnd !== "last" && (
        <Btn onClick={nextHandler} shadowcolor='#BC6022' bgColor='secondary'>
          <ArrowRightIcon
            color='white'
            stroke='secondaryDark'
            fontSize='24px'
          />
        </Btn>
      )}
    </HStack>
  );
}

export default ComicControls;
