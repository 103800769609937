import { Grid } from "@chakra-ui/react";
import GameItem from "./GameItem";

function AvailableGames({ games, minW = "200px" }) {
  return (
    <Grid
      alignItems='center'
      justifyItems='center'
      templateColumns={`repeat( auto-fit, minmax(${minW}, 1fr) )`}
      margin='16px auto'
      maxW='1200px'
      gap='8px'
      padding='16px'
      borderRadius='10px'
    >
      {games.map((game) => {
        const { title, thumbnail, id, ratings } = game;
        return (
          <GameItem
            title={title}
            thumbnail={`https://api.leaderbored.gg${thumbnail.url}`}
            id={id}
            key={id}
            rating={ratings}
          />
        );
      })}
    </Grid>
  );
}

export default AvailableGames;
