import { Box, Flex, useMediaQuery } from "@chakra-ui/react";

import DesktopNav from "./DesktopNav";
import homeIcon from "../assets/Nav-Icons/LBPortal_Graphic_Home.png";
import contactIcon from "../assets/Nav-Icons/LBPortal_Graphic_Contact.png";
import aboutIcon from "../assets/Nav-Icons/LeaderBored_Icon_About.png";

import {
  IoLogoInstagram,
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoYoutube,
  IoLogoTiktok,
} from "react-icons/io5";
import MobileNav from "./MobileNav";

const Navbar = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const navItems = [
    {
      text: "Home",
      path: "/",
      icon: homeIcon,
    },
    {
      text: "About",
      path: "/about",
      icon: aboutIcon,
    },
    {
      text: "Contact",
      path: "/contact",
      icon: contactIcon,
    },
  ];

  const socialIcons = [
    {
      icon: IoLogoInstagram,
      link: "https://www.instagram.com/leaderboredgg/",
    },
    {
      icon: IoLogoFacebook,
      link: "https://www.facebook.com/LeaderBoredGames/",
    },
    {
      icon: IoLogoTwitter,
      link: "https://twitter.com/LeaderBoredGG/",
    },
    {
      icon: IoLogoYoutube,
      link: "https://www.youtube.com/channel/UCVJjC7WCRuhe8ENydNuQTgQ",
    },
    {
      icon: IoLogoTiktok,
      link: "https://www.tiktok.com/@leaderboredgg",
    },
  ];

  return (
    <Box pos='fixed' zIndex='99' w='100%' color='white' bg='primary'>
      <Flex
        justifyContent='space-between'
        alignItems='center'
        mx='auto'
        maxW='1200px'
        h={16}
        py='40px'
      >
        {isLargerThan768 && (
          <DesktopNav navItems={navItems} socials={socialIcons} />
        )}
        {!isLargerThan768 && (
          <MobileNav navItems={navItems} socials={socialIcons} />
        )}
      </Flex>
    </Box>
  );
};

export default Navbar;
