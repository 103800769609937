import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    primary: "#267AC2",
    primaryLight: "#26A7DB",
    primaryDark: "#014C7A",
    secondary: "#FBB03B",
    secondaryDark: "#BC6022",
  },
});
