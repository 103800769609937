import { Button } from "@chakra-ui/react";

function Btn(props) {
  const {
    children,
    bgColor = "primaryLight",
    color = "black",
    shadowcolor = "#075EA5",
    type = "submit",
    animate = "true",
  } = props;

  const boxShadows = {
    base: `0 3px 0 ${shadowcolor}`,
    sm: `0 6px 0 ${shadowcolor}`,
  };

  return (
    <Button
      type={type}
      fontWeight='bold'
      borderRadius={{ base: "10px", sm: "20px" }}
      alignSelf='center'
      px={{ base: "16px", sm: "24px" }}
      py={{ base: "4px", sm: "8px" }}
      w='fit-content'
      boxShadow={boxShadows}
      bgColor={bgColor}
      color={color}
      _hover={{
        color: bgColor,
        bgColor: "white",
      }}
      _active={
        animate === "true"
          ? {
              transform: "translateY(4px)",
              boxShadow: `0 2px 0 ${shadowcolor}`,
            }
          : ""
      }
      {...props}
    >
      {children}
    </Button>
  );
}

export default Btn;
