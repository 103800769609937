import { Box, Heading } from "@chakra-ui/react";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";

import FilteredGames from "../Components/Games/FilteredGames";
import { useGames } from "../Hooks/useGetGames";
import LoadingSkeletons from "../Components/UI/LoadingSkeletons";
import Carousel from "../Components/Carousel/Carousel";
import GameBannerAd from "../Components/Advertisement/GameBannerAd";
import ComicPanel from "../Components/Comics/ComicPanel";
import taktLogo from "../assets/LBPortal_Graphic_AdBG.webp";

function Homepage() {
  ReactGA.send({
    hitType: "pageview",
    page: "/",
    title: "Home",
  });
  const { data, isLoading } = useGames();

  if (isLoading)
    return (
      <>
        <Heading color='white' textAlign='center' textShadow='2px 2px black'>
          Loading Home Page
        </Heading>
        <LoadingSkeletons />
      </>
    );

  const carouselGames = data.games.filter(
    (game) => game.is_display_in_slideshow === true
  );

  const singlePlayer = data.games.filter(
    (item) => !item.description.includes("Multiplayer")
  );

  const multiPlayer = data.games.filter((item) =>
    item.description.includes("Multiplayer")
  );

  return (
    <>
      <Helmet>
        <title>Leaderbored</title>
        <link rel='canonical' href='https://www.leaderbored.gg/home' />
      </Helmet>
      <Box color='white'>
        <Carousel games={carouselGames} />
        <GameBannerAd />
        <Box id='comics'>
          <ComicPanel />
        </Box>

        <Box
          bgImage={taktLogo}
          bgPos='center'
          bgSize='cover'
          borderRadius='20px'
          mb='40px'
          bgRepeat='no-repeat'
        >
          <FilteredGames
            heading={"GAME OF THE WEEK"}
            games={[data.featuredGame]}
          />
        </Box>
        <FilteredGames heading={"Single player"} games={singlePlayer} />
        <GameBannerAd />
        <FilteredGames heading={"Multiplayer"} games={multiPlayer} />
      </Box>
    </>
  );
}

export default Homepage;
