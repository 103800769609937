import { Box, Heading, Text } from "@chakra-ui/react";

import AvailableGames from "./AvailableGames";

function FilteredGames(props) {
  const { games, heading } = props;
  return (
    <Box bgColor='whiteAlpha.500' overflow='hidden' borderRadius='16px'>
      <Heading
        alignItems='center'
        justifyContent='space-between'
        display='flex'
        p='8px'
        bgColor='primary'
        fontSize='2xl'
      >
        <Text>{heading}</Text>
        {/* 
        <Text
          as={Link}
          to='/games'
          _hover={{
            textDecor: "underline",
            opacity: "1",
          }}
          pr='8px'
          opacity='.75'
          fontSize='sm'
        >
          All games &rsaquo;
        </Text> */}
      </Heading>

      <AvailableGames games={games} />
    </Box>
  );
}

export default FilteredGames;
