import { Box, Icon, HStack, Image, Avatar, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import Searchbar from "./Searchbar";
import logo from "../assets/Leaderbored_logo_HD.webp";
import { getAuthToken } from "../utilities/auth";
import loginIcon from "../assets/Nav-Icons/LBPortal_Graphic_Login.png";

const DesktopNav = ({ navItems, socials }) => {
  const token = getAuthToken();

  const rendernavItems = navItems.map((item) => (
    <Box
      borderRadius='10px'
      _hover={{
        bgColor: "whiteAlpha.500",
      }}
      p='10px'
      as={Link}
      key={item.text}
      to={item.path}
    >
      <VStack>
        <Image maxH='24px' src={item.icon} alt='Navigation icon' />
        <Box>{item.text}</Box>
      </VStack>
    </Box>
  ));

  const renderSocials = socials.map((social) => {
    return (
      <Link target='_blank' to={social.link} key={social.link}>
        <Icon as={social.icon} boxSize={8} />
      </Link>
    );
  });

  const loginNav = (
    <Box
      borderRadius='10px'
      _hover={{
        bgColor: "whiteAlpha.500",
      }}
      p='10px'
      as={Link}
      to='/auth'
    >
      <VStack>
        <Image maxH='24px' src={loginIcon} alt='Navigation icon' />
        <Box>Login</Box>
      </VStack>
    </Box>
  );

  return (
    <>
      <Link to='/'>
        <Image w='72px' src={logo} alt='Leaderbored logo' />
      </Link>
      <Box boxSize={10} />
      {rendernavItems}
      {!token && loginNav}
      <HStack gap='16px'>
        <Searchbar />
        {token && (
          <Link to='/account'>
            <Avatar as='button' bg='primaryLight' boxSize={10} />
          </Link>
        )}
      </HStack>
      <HStack gap='16px'>{renderSocials}</HStack>
    </>
  );
};

export default DesktopNav;
