import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  HStack,
  Icon,
} from "@chakra-ui/react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
} from "react-share";
import { BsFacebook, BsLinkedin, BsReddit, BsTwitter } from "react-icons/bs";

import Btn from "../UI/Btn";

function ShareModal({ data }) {
  const { title, page } = data;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const url = `https://www.leaderbored.gg?comic=${page}#comics`;

  return (
    <Box>
      <Btn onClick={onOpen} shadowcolor='#BC6022' bgColor='secondary'>
        SHARE
      </Btn>

      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Share on:</ModalHeader>
          <ModalCloseButton />
          {/* <ModalBody>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime quae
            iure voluptate fugit eveniet atque sed pariatur, sapiente
            perspiciatis vero labore. Libero corrupti aperiam, corporis suscipit
            nesciunt dolor eveniet dolorem.
          </ModalBody> */}

          <ModalFooter>
            <HStack justifyContent='center' gap='16px' w='100%' fontSize='40px'>
              <FacebookShareButton url={url}>
                <Icon as={BsFacebook} color='facebook.500' />
              </FacebookShareButton>
              <TwitterShareButton url={url} title={`Checkout ${title} at`}>
                <Icon as={BsTwitter} color='twitter.500' />
              </TwitterShareButton>
              <LinkedinShareButton source={url} url={url}>
                <Icon as={BsLinkedin} color='linkedin.500' />
              </LinkedinShareButton>
              <RedditShareButton title={title} url={url}>
                <Icon as={BsReddit} color='#ff4500' />
              </RedditShareButton>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default ShareModal;
