import { Suspense, lazy, useEffect } from "react";
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";

import Layout from "./Layout/Layout";
import Home from "./Pages/Home";
import PageNotFound from "./Pages/PageNotFound";
import { getAuthToken, updateUserCache } from "./utilities/auth";
import Fallback from "./Pages/Fallback";

const GamePage = lazy(() => import("./Pages/GamePage"));
const SearchResult = lazy(() => import("./Pages/SearchResult"));
const AuthPage = lazy(() => import("./Pages/AuthPage"));
const Account = lazy(() => import("./Pages/Account"));
const ResetPasswordPage = lazy(() => import("./Pages/ResetPasswordPage"));
const Contact = lazy(() => import("./Pages/ContactUs"));
const About = lazy(() => import("./Pages/About"));

const router = createBrowserRouter([
  {
    path: "/",
    id: "root",
    element: <Layout />,
    errorElement: <PageNotFound />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "about",
        element: (
          <Suspense fallback={<Fallback />}>
            <About />,
          </Suspense>
        ),
      },
      // {
      //   path: "games",
      //   element: (
      //     <Suspense fallback={<Fallback />}>
      //       <AllGames />
      //     </Suspense>
      //   ),
      // },
      {
        path: "games/:gameId",
        element: (
          <Suspense fallback={<Fallback />}>
            <GamePage />
          </Suspense>
        ),
      },
      {
        path: "search",
        element: (
          <Suspense fallback={<Fallback />}>
            <SearchResult />
          </Suspense>
        ),
      },
      {
        path: "auth",
        element: (
          <Suspense fallback={<Fallback />}>
            <AuthPage />
          </Suspense>
        ),
        loader: () =>
          import("./utilities/auth").then((module) => module.checkAuthLoader()),
      },
      {
        path: "auth/reset",
        element: (
          <Suspense fallback={<Fallback />}>
            <ResetPasswordPage />
          </Suspense>
        ),
      },
      {
        path: "account",
        element: (
          <Suspense fallback={<Fallback />}>
            <Account />
          </Suspense>
        ),
        loader: () =>
          import("./utilities/auth").then((module) => {
            const token = module.getAuthToken();
            if (token) return null;
            else return redirect("/");
          }),
      },
      {
        path: "contact",
        element: (
          <Suspense fallback={Fallback}>
            <Contact />
          </Suspense>
        ),
      },
    ],
  },
]);

function App() {
  const token = getAuthToken();
  useEffect(() => {
    if (token) {
      updateUserCache();
    }
  }, [token]);

  return <RouterProvider router={router} />;
}

export default App;
