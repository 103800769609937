import { Heading } from "@chakra-ui/react";

function Fallback() {
  return (
    <Heading textAlign='center' mt='80px'>
      Loading...
    </Heading>
  );
}

export default Fallback;
