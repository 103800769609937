import { Grid, Skeleton } from "@chakra-ui/react";

function LoadingSkeletons({ length = 6, height = "200px", width = "200px" }) {
  const arr = Array.from({ length });
  return (
    <Grid
      alignItems='center'
      justifyItems='center'
      templateColumns='repeat( auto-fit, minmax(180px, 1fr) )'
      margin='16px auto'
      maxW='1200px'
      gap='4px 8px'
    >
      {arr.map((_, i) => (
        <Skeleton key={i} height={height} width={width} />
      ))}
    </Grid>
  );
}

export default LoadingSkeletons;
