import { useQuery } from "react-query";
import axiosInstance from "../axios/axiosInstance";

const getGamesFn = async () => {
  const { data } = await axiosInstance.get(
    `games?featuredGame=true&editorsPick=true`
  );

  return data;
};

export function useGames() {
  return useQuery(["games", 1], getGamesFn);
}

const getSingleGameFn = async (gameId) => {
  const { data } = await axiosInstance.get(`games/${gameId}`);
  return data;
};

export function useSingleGame(gameId) {
  return useQuery("game", () => getSingleGameFn(gameId));
}
