import { Box, Heading } from "@chakra-ui/react";

import { useCallback, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";

import axiosInstance from "../../axios/axiosInstance";
import Strips from "./Strips";
import ComicControls from "./ComicControls";
import { useSearchParams } from "react-router-dom";

function ComicPanel() {
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(searchParams.get("comic") || 0);
  const [maxPage, setMaxPage] = useState(2);
  const [comicData, setComicData] = useState(null);
  const [strips, setStrips] = useState([]);
  const [isInit, setIsInit] = useState(true);

  const fetchComicFn = useCallback(
    async (fetchPage = page) => {
      const { data } = await axiosInstance.get(
        `/webconomics?populate[webconomics_strips][populate][strips][fields][1]=url&pagination[start][1]=${fetchPage}&pagination[limit][1]=1`
      );
      return data;
    },
    [page]
  );
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(["comic", page], () => fetchComicFn(), {
    keepPreviousData: true,
  });

  useEffect(() => {
    const nextPage = page + 1;
    if (nextPage > maxPage) return;
    queryClient.prefetchQuery(["comic", nextPage], () =>
      fetchComicFn(nextPage)
    );
  }, [page, queryClient, fetchComicFn, maxPage]);

  useEffect(() => {
    if (data && !isLoading) {
      if (isInit && !searchParams.get("comic")) {
        setPage(data.meta.pagination.total - 1);
      }
      const newStrips = data.data[0].attributes.webconomics_strips.strips.data;
      setComicData(data.data[0]);
      setStrips(newStrips);
      setMaxPage(data.meta.pagination.total - 1);
      setIsInit(false);
    }
  }, [data, isLoading, isInit, searchParams]);

  if (isLoading)
    return (
      <Box
        bgColor='white'
        overflow='hidden'
        borderRadius='16px'
        my='40px'
        minH='500px'
      >
        <Heading my='40px'>Loading comics...</Heading>;
      </Box>
    );

  const nextHandler = () => {
    if (page === maxPage) return;
    else setPage((prev) => prev + 1);
  };
  const prevHandler = () => {
    if (page === 0) return;
    else setPage((prev) => prev - 1);
  };

  const randomHandler = () => {
    let randomPage = Math.floor(Math.random() * maxPage + 1);
    while (randomPage === page) {
      randomPage = Math.floor(Math.random() * maxPage + 1);
    }
    setPage(randomPage);
  };

  const isOnEndHandler = () => {
    if (page === 0) return "first";
    if (page === maxPage) return "last";
    return null;
  };
  console.log("render");
  return (
    <>
      <Box
        bgColor='white'
        overflow='hidden'
        minH='500px'
        borderRadius='16px'
        my='40px'
      >
        <Heading
          alignItems='center'
          justifyContent='space-between'
          display='flex'
          p='8px'
          bgColor='primary'
          fontSize='2xl'
        >
          COMIC OF THE DAY
        </Heading>
        <Strips comicData={comicData} strips={strips} />
        <ComicControls
          isOnEnd={isOnEndHandler()}
          prevHandler={prevHandler}
          firstHandler={() => setPage(0)}
          randomHandler={randomHandler}
          lastHandler={() => setPage(maxPage)}
          nextHandler={nextHandler}
          shareData={{
            title: comicData?.attributes.title,
            page,
          }}
        />
      </Box>
    </>
  );
}

export default ComicPanel;
