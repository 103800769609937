import { Button, Text, HStack, Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { getAuthToken, getCurUser } from "../../utilities/auth";
import { useRateGame } from "../../Hooks/useRating";

function Rating(props) {
  const { rating, functional } = props;
  const isFunctional = functional === "true" ? true : "";

  const [isRated, setIsRated] = useState(false);
  const [userRating, setUserRating] = useState(1);
  const isRating = isFunctional && getAuthToken();
  const [ratingIndex, setRatingIndex] = useState(0);
  const token = getAuthToken();
  const user = getCurUser();
  const gameId = useParams().gameId;

  useEffect(() => {
    if (user) {
      const { ratings } = user;
      const userRatedGames = ratings.map((item) => String(item.game.id));
      if (ratings.length !== 0) {
        const storedRating = ratings.find((item) => item.game.id == gameId);
        if (storedRating) {
          setIsRated(userRatedGames.includes(gameId));
          setUserRating(storedRating.rate);
        }
      }
    }
  }, [gameId]);

  const handleGoldStarWidth = (i) => {
    if (!isRating) {
      const percentage = (rating % 1) * 100;
      const starPosition = i + 1;

      if (starPosition <= rating) return "100%";
      else if (starPosition > rating && starPosition < rating + 1)
        return percentage + "%";
      else return "0";

      // else return percentage + "%";
    } else {
      return i < ratingIndex ? "100%" : "0";
    }
  };

  const { mutate: rateGame } = useRateGame();

  const submitHandler = async (rate) => {
    if (!isRating) return;
    const body = {
      data: {
        game: Number(gameId),
        rate,
      },
    };
    rateGame(body);
    setIsRated(true);
    setUserRating(rate);
    const newUserInfo = {
      ...user,
      ratings: [...user.ratings, { rate, game: { id: gameId } }],
    };
    localStorage.setItem("user", JSON.stringify(newUserInfo));
  };

  if (isFunctional && token && isRated)
    return (
      <Text fontSize='20px'>
        You rated this{" "}
        <Box as='strong' color='gold' display='inline'>
          {userRating}
        </Box>
        /5
        {/* {`${userRating}/5`}{" "} */}
      </Text>
    );

  if (isFunctional && !token)
    return (
      <Button as={Link} to='/auth' color='primary' bgColor='white'>
        Log in to submit rating
      </Button>
    );

  return (
    <HStack {...props}>
      {[...Array(5)].map((_, i) => (
        <Box
          onMouseLeave={() => setRatingIndex(0)}
          onMouseEnter={() => setRatingIndex(i + 1)}
          _hover={{
            cursor: isRating ? "pointer" : "",
          }}
          onClick={() => submitHandler(i + 1)}
          key={i}
        >
          <Box
            clipPath='polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)'
            w='24px'
            h='24px'
            bg='gray'
          >
            <Box w={() => handleGoldStarWidth(i)} h='100%' bg='gold' />
          </Box>
        </Box>
      ))}
    </HStack>
  );
}

export default Rating;
