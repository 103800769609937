import { useMutation } from "react-query";
import axiosInstance from "../axios/axiosInstance";
import { getAuthToken } from "../utilities/auth";

const rateFn = async (body) => {
  const { data } = await axiosInstance.post("ratings", body, {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  return data;
};

export const useRateGame = () => {
  return useMutation((body) => rateFn(body));
};
