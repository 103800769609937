import { Center, Heading, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

function PageNotFound() {
  return (
    <>
      <Helmet>
        <title>Page not found</title>
      </Helmet>
      <Center mb='16px' mt='80px'>
        <Heading>
          <Text as='span' color='red'>
            Error:{" "}
          </Text>
          Page not found
        </Heading>
      </Center>
      <Text
        textDecor='underline'
        display='block'
        as={Link}
        to='/'
        textAlign='center'
      >
        Back to home
      </Text>
    </>
  );
}

export default PageNotFound;
