import { Box, Image, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import placeholderThumbnail from "../../assets/GameThumbnail_Empty.png";
import Rating from "../UI/Rating";

function GameItem({ title, thumbnail, id, rating }) {
  const maxCharacters = 12;
  const trimmedTitle =
    title.length > maxCharacters
      ? `${title.slice(0, maxCharacters)}...`
      : title;

  return (
    <Box
      as={Link}
      to={`/games/${id}`}
      color='white'
      borderRadius='8px'
      boxShadow='0 4px 8px lightgray'
      padding='4px'
      pb='12px'
      bgColor='primary'
      _hover={{
        cursor: "pointer",
        textDecoration: "underline",
      }}
    >
      <Image
        onLoad={(e) => (e.target.src = thumbnail)}
        marginBottom='16px'
        borderRadius='8px'
        alt={title}
        src={placeholderThumbnail}
        loading='lazy'
      />
      <Rating justifyContent='center' rating={rating} />
      <Text textAlign='center' fontSize='xl'>
        {trimmedTitle}
      </Text>
    </Box>
  );
}

export default GameItem;
