import { Box, Text, Image } from "@chakra-ui/react";
import placeholder from "../../assets/GameThumbnail_Empty.png";

function Strips({ comicData, strips }) {
  return (
    <Box
      pt='40px'
      mb='24px'
      mx='auto'
      w='fit-content'
      pos='relative'
      minHeight='200px'
      display='flex'
      flexDir={{ base: "column", md: "row" }}
    >
      <Text
        pos='absolute'
        color='black'
        fontSize='24px'
        textTransform='uppercase'
        top='0'
        left='12px'
      >
        {comicData?.attributes.title}
      </Text>
      {strips.map((item) => {
        return (
          <Box key={item.id}>
            <Image
              w={{ base: "400px", lg: "320px" }}
              // src={`https://api.leaderbored.gg${item.attributes.url}`}
              src={placeholder}
              loading='lazy'
              onLoad={(e) =>
                (e.target.src = `https://api.leaderbored.gg${item.attributes.url}`)
              }
              transform={
                item.id === 117 && { base: "scale(1.435) translate(-15%,15%)" }
              }
            />
          </Box>
        );
      })}
    </Box>
  );
}

export default Strips;
